import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { PropTypes, DefaultProps } from '../../../core/Utils/SitecoreFields';

import ImageContentLink from '../../../core/Components/ImageContentLink';
import Link from '../../../core/Components/Link';

const HeroCard = ({ fields }) => {
  const { link, secondaryLink, isSecondary } = fields;
  const linkWrapped =
    !isExperienceEditorActive() && link.value.href && !secondaryLink.value.href;
  const content = linkWrapped ? (
    <Link
      field={fields.link}
      title={fields.link.value.title || fields.title.value}
      className="content-card__grouper"
    >
      <ImageContentLink fields={fields} />
    </Link>
  ) : (
    <div className="content-card__grouper">
      <ImageContentLink fields={fields} />
    </div>
  );

  return (
    <div
      className={`content-card hero-card ${
        isSecondary.value ? 'is-secondary' : ''
      }`}
    >
      {content}
    </div>
  );
};

HeroCard.propTypes = PropTypes.SitecoreItem.inject({
  ...PropTypes.ImageContentLink,
  isSecondary: PropTypes.Checkbox,
  secondaryLink: PropTypes.Link,
});

HeroCard.defaultProps = DefaultProps.SitecoreItem.inject({
  ...DefaultProps.ImageContentLink,
  isSecondary: DefaultProps.Checkbox,
  secondaryLink: DefaultProps.Link,
});

export default HeroCard;
